import React, { useState, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  image,
  imageContainer,  
} from "./fournisseur.module.css";

function Carroussel({ imageData }) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsMouseDown(true);
    setStartX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    containerRef.current.scrollLeft -= e.clientX - startX;
    setStartX(e.clientX);
  };

  

  return (
    <div
      ref={containerRef}
      className={imageContainer}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {imageData?.map(
        ({ gatsbyImageData, id }) => {
          return (
            <div key={id} >
              <GatsbyImage
                image={gatsbyImageData}
                alt={`herlea btp images showroom produits salle de bain porcelanosa`}
                className={image}                
              />
            </div>
          );
        }
      )}
    </div>
  );
}
export default Carroussel;
